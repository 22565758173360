import React from 'react'
import {Routes,Route} from "react-router-dom"
import Pricing from '../modules/Components/Pricing/Pricing'
import Promotion from '../modules/Components/Promotion/Promotion'
import Trade from '../modules/Components/Trade/Trade'
import Assortments from "../modules/Components/Assortment/Assortments"
import IntegratedTools from '../modules/Components/Integrated-Tools/IntegratedTools'
import NotFound from '../modules/Components/Not-Found-Page/NotFound'

const AllRoutes = () => {
  return (
    <>
    <Routes>
        <Route path='/' element={<Promotion></Promotion>}></Route>
        <Route path="/pricing" element={<Pricing></Pricing>} ></Route>
        <Route path='/assortment' element={<Assortments></Assortments>}></Route>
        <Route path='/trade' element={<Trade></Trade>}></Route>
        <Route path='/integrated' element={<IntegratedTools></IntegratedTools>}></Route>
        <Route path='*' element={<NotFound></NotFound>}></Route>
    </Routes>
    </>
    
  )
}

export default AllRoutes