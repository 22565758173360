import React from 'react'
import Tool from '../Tool/Tool'
import { integratedToolData } from '../../../config/rgmData'

const IntegratedTools = () => {
  return (
    <Tool toolData={integratedToolData}></Tool>
  )
}

export default IntegratedTools