
export const assortmentsData = [
    {
        logoLink:'/assets/Images/Assortments/Assortment Optimization Tool.png',
        name:'Assortment Prioritization',
        description:'Helps to identify the top prioritized Core/Strategic SKUs(Must Sell SKUs) for better reach and offering at various granularity (Segment * Chain * RE * Nielsen Area) to minimize the impact of OOS.',
        projectLink:''
    },
    {
        logoLink:'/assets/Images/Assortments/Predictive  Suggested Order.png',
        name:'Predictive Order',
        description:"AI-powered tool that generates HYPER PERSONALIZED 'Suggested Orders' for every retailer at every visit, thereby maximizing ‘Economic Value",
        projectLink:''
    },
    {
        logoLink:'/assets/Images/Assortments/Activation- analytics-LH4.png',
        name:'Activation Analytics',
        description:'Building effective activation strategies to increase visual stimuli at POS to increase the probability of catching the shopper’s eye and thus generate more purchasing opportunities.',
        projectLink:'https://tableau.kellogg.com/#/site/KLA/workbooks/11054/views'
    },
    {
        logoLink:'/assets/Images/Assortments/AMPPS.png',
        name:'AMPPS *',
        description:'Helps to identify the Store level Opportunity at Channel, Format, Store, Category, Brand, and SKU levels with Compliance Tracking Ability.',
        projectLink:'https://kh-lh4-ampps.decisionpoint.in/'
    },
    {
        logoLink:'/assets/Images/Assortments/AOS.png',
        name:'AOS',
        description:'A Web Based tool that generates value and efficient decision-making for the business , it Shows current KPIs and their interaction with each other: OSA, Instock, Fill rate, OnTime .',
        projectLink:'http://kl-aos-frontend-landing-qa.decisionpoint.in/'
    },
    {
        logoLink:'/assets/Images/Trade/Planogram Tool.png',
        name:'Asset Planogram Tool',
        description:'Helps to identify how much specific retail products(Must Sell SKUs/Promoted Products) should be placed on retail shelves or displays in order to increase customer purchases.',
        projectLink:'https://ko-cde-in-qa-frontend-global.azurewebsites.net/planograms'
    },
]

export const integratedToolData = [
    {
        logoLink:'/assets/Images/IntegratedTools/Demand Forecasting Solutions and Simulation Tools (1).png',
        name:'Demand Planning',
        description:'The forecasting simulator tool shows the forecasted scenario based on variables like the macro, covid, commercial variables and category, channel and trend summary.',
        projectLink:''
    },
    {
        logoLink:'/assets/Images/IntegratedTools/Integrated Business Planning Platform (IBP Tool).png',
        name:'Integrated Commercial Planner/HILO *',
        description:'It brings together the base volume projections, Building Blocks (Pricing, New products, Promotions and Advertising, Trade Inventory, Distribution/ New channels and delisting) and Supply Chain Analytics (Production & Material scheduling, capacity constraint visibility) to provide holistic and collaborative Planning Process enabling joint decisions to balance Demand and Supply.',
        projectLink:' '
    },
    {
        logoLink:'/assets/Images/IntegratedTools/target-builder.png',
        name:'Target Group Builder *',
        description:'Focuses on growth opportunities by identifying correct performance of brands and identifying group of stores for activity, campaign and launch product.',
        projectLink:'https://lh4-target-builder-dev.decisionpoint.in/plan-list'
    },
    {
        logoLink:'/assets/Images/IntegratedTools/E2E-Shopper-POS.png',
        name:'E2E Shopper/ POS Initiative *',
        description:'The objective of the screen is to create a target audience based on the product objective and selected product portfolio. The tool will recommend the shopper persona based on the objective and product portfolio.',
        projectLink:'http://lh8-mex-tool.us-east-1.elasticbeanstalk.com/target-group'
    },
    {
        logoLink:'/assets/Images/IntegratedTools/Growth Decomposition Tool.png',
        name:'Market Share Tool (Growth decomp Tool)',
        description:'Predict the expected market share from Retail audit based on the depth, width, price etc. factors. Highlight the growth and share gain opportunity based on the drivers and gaps.',
        projectLink:'https://app.powerbi.com/groups/f238eac0-3669-409c-b19b-8cbf2710b02f/reports/38a97e29-9480-4dfc-a694-6501ea375736/ReportSection4d7789daca928a170cd8'
    },
    {
        logoLink:'/assets/Images/IntegratedTools/Simulation Chatbots.png',
        name:'Simulator Chatbot',
        description:'It can engage users(business leaders, Analytic leaders) with natural language helping them to get quick update of pricing , promotions, assortment or deepdive into diagnosis like “What would be the impact of price change of a particular product?”',
        projectLink:'https://teams.microsoft.com/l/app/9557db89-7c5d-4189-b408-254ab49368c9?source=app-details-dialog'
    }  
]

export const pricingData = [
    {
        logoLink:'/assets/Images/Pricing/Price Diagnostics Tools.png',
        name:'Pricing Dashboard',
        description:'Provides actionable insights on Brand Tiering and stratification, Moment based pack role, price ladder, price index vs. competition to highlight the whitespaces, price index and share gain opportunities.',
        projectLink:'https://app.powerbi.com/reportEmbed?reportId=f7a8cfa4-bfea-4002-9eb0-f8335df88262&autoAuth=true&ctid=77184d99-6065-42b9-9452-8b2b70385193&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLWluZGlhLXdlc3QtcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQvIn0%3D'
    },
    {
        logoLink:'/assets/Images/Pricing/Purchase-Structure.png',
        name:'Purchase structure',
        description:'Explains the order and highlights the attributes which are more differentiated or offer unique offerings vs. others',
        projectLink:''
    },
    {
        logoLink:'/assets/Images/Pricing/Price Volume Profit Simulator.png',
        name:'PVP *',
        description:'Helps to simulate price scenarios to identify the impact on volume basis price changes and identify optimal price which maximizes profit.',
        projectLink:'https://iapkelloggs.decisionpoint.in/'
    },
    
]

export const promotionData = [
    {
        logoLink:'/assets/Images/Promo/Post Event Analyzer.png',
        name:'POST EVENT - Promo Diagnosis dashboards',
        description:'Helps to analyse Top line Promo Performance details through critical business and Performance KPI(price, discount depth and promo investment) and Identify key areas for deep dive to maximize volume lift, profit.',
        projectLink:'https://app.powerbi.com/Redirect?action=OpenApp&appId=63aecb4c-ac41-4e88-b504-c6ca7b98f4de&ctid=7094d542-3815-4c82-b1d5-6917d0443cf4'
    },
    {
        logoLink:'/assets/Images/Promo/Promo Simulator & Planner.png',
        name:'Promo What-if/ Optimizer *',
        description:'Allows user to create different promotion scenarios for applicable week, customer, channel etc. and provide expected Sales (Kg) uplift and RoI as output. Hence allowing the users to select the best promotion to maximize ROI.',
        projectLink:'https://lh3-whatif-col-dev.decisionpoint.in/scenario-library'
    },
    
]

export const tradeData = [
    {
        logoLink:'/assets/Images/Trade/Selling Story (Fable).png',
        name:'Fable (Selling Story)',
        description:'Hyper personalized selling stories for every customer and SKU combination. Get the right story to the right customer.',
        projectLink:''
    },
    {
        logoLink:'/assets/Images/Trade/Asset Placement Simulator.png',
        name:'Asset Placement Simulator',
        description:'Evaluates the location, consumer, consumption etc. to Identify the ideal placement of the asset in a region.',
        projectLink:'https://ko-cde-qa-placement-in-frontend.azurewebsites.net/scenario-library'
    },

    
]

// export let allData = {
//     'Promotion':promotionData,
//     'Pricing':pricingData,
//     'Assortment':assortmentsData,
//     'Trade':tradeData,
//     'Integrated Tools':integratedToolData
// }

