import React from 'react'

const Tool = ({toolData}) => {
  return (
    <div className="promotion-section">
    <div className="promo-content">
      {toolData.map((tool) => 
        (<a href={tool.projectLink ? tool.projectLink : false} target="blank">
          <div className={tool.projectLink ? "promo-logo" : 'grey-color'} >
            { tool.logoLink ? 
                <img
                src={tool.logoLink}
                alt={tool.name}
                className="promo-icons"
              /> : ""
            }
          </div>
          <h6 className="promo-name">{tool.name}</h6>
          <p className="promo-oneLiner">{tool.description}</p>
        </a>)
      )}
    </div>
  </div>
  )
}

export default Tool