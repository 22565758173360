import React from "react";
import { assortmentsData } from "../../../config/rgmData";
import Tool from '../Tool/Tool'

const Assortments = () => {
  return (
    <Tool toolData={assortmentsData}></Tool> 
  );
};

export default Assortments;
