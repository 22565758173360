import React from 'react'
import "./navigation.css"
import { NavLink } from "react-router-dom";
import AllRoutes from "../../routes/AllRoutes"
import { toolCategory } from '../../config/navigationLink';

const Navigation = () => {

    let activeClass = {
        color: "#222222",
        padding:'8%',
        backgroundColor: "#FFFFFF",
        boxShadow: "0px 7px 29px rgba(34, 34, 34, 0.17)",
        borderLeft:'3px solid  #DB1625 ',
        margin:'-22px',
        fontWeight: 600
      };

  return (
    <div className="main-content">
        <div className="options">
          {toolCategory.map( navLink =>
          (
            <NavLink
            to={navLink==='Promotion'? '/' : navLink.split(" ")[0]}
            style={({ isActive }) => (isActive ? activeClass : undefined)}
          >
            {navLink}
          </NavLink>
          )  
            )}
        </div>
        <AllRoutes></AllRoutes>
      </div>
  )
}

export default Navigation