import React from 'react'
import Tool from '../Tool/Tool'
import './promotion.css'
import { promotionData } from '../../../config/rgmData'


const Promotion = () => {
  return (
    <Tool toolData={promotionData}></Tool>
  )
}

export default Promotion