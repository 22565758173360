import React from "react";
import "./subheader.css";

const Subheader = () => {
 

  return (
    <div>
      <div className="heading-txt">
        Welcome to <span>Hubble</span> !
      </div>
      <div>
        The most advanced RGM tool to unlock growth opportunities across the RGM
        universe.
      </div>
    </div>
  );
};

export default Subheader;
