import React from 'react'
import Tool from '../Tool/Tool'
import { tradeData } from '../../../config/rgmData'

const Trade = () => {
  return (
    <Tool toolData={tradeData}></Tool>
  )
}

export default Trade