import React from "react";
import Tool from "../Tool/Tool";
import "./pricing.css";
import { pricingData } from "../../../config/rgmData";

const Pricing = () => {
  return (
    <Tool toolData={pricingData}></Tool>
  );
};

export default Pricing;
