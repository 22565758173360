import React from "react";
import { Link } from "react-router-dom";
import "./header.css";

const Header = () => {
  return (
    <div className="header">
      <Link to={"/"} className="hubbled-logo">
        <div>
          <img
            src={"/assets/Images/upperLine.svg"}
            alt=""
            width="28px"
            className="upper-line"
          />
          <img src={"./assets/Images/d.svg"} alt="" className="d" />
          <img
            src={"./assets/Images/lowerLine.svg"}
            alt=""
            className="lower-line"
          />
        </div>
        <div className="hubble-name">hubble</div>
      </Link>

      <div className="user">
        <span className="welcome-text"> Welcome </span>{" "}
        <span className="user-text">&nbsp;Samuel</span>
        <span style={{ marginLeft: "4px", marginTop: "2px" }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20px"
            width="20px"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            strokeWidth={2}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </span>{" "}
        <div className="vr-line"></div>
        <div>
          <img src={"./assets/Images/dpLogo.svg"} alt="" />
        </div>
      </div>
    </div>
  );
};

export default Header;
