import Header from './modules/Header/Header';
import Navigation from './modules/Navigation/Navigation';
import Subheader from './modules/Subheader/Subheader';

function App() {
  return (
    <>
      <Header></Header>
      <Subheader></Subheader>
      <Navigation></Navigation>
    </>
  );
}

export default App;
